/**
 * Created by osirvent on 16/02/2017.
 */
'user strict';
angular
    .module('annexaApp')
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('annexa.sec', {
                absctract: true,
                url: '/sec',
                redirectTo: 'annexa.sec.proposals',
                views: {
                    "": {
                        controller: 'SecController',
                        templateUrl: './views/layout/sec.html'
                    }
                },
                data: {
                    title: 'global.sec.toptitle',
                    displayName: 'global.sec.toptitle',
                    authenticate: true,
                    permissions: {
                        only: ['view_proposals', 'view_sessions', 'view_decrees']
                    }
                },
                resolve: {
                    getDocumentLists: ['DccumentsFactory', function (DccumentsFactory) {
                        return DccumentsFactory.getDocumentLists();
                    }]
                } 
            })
            .state('annexa.sec.sessions', {
                url: '/sessions',
                views: {
                    "mainsec@annexa.sec": {
                        controller: 'SecSessionsController',
                        templateUrl: './views/layout/sessions.html'
                    }
                },
                data: {
                    displayName: 'global.sec.literals.sessions',
                    authenticate: true,
                    permissions: {
                        only: 'view_sessions'
                    }
                },
                resolve: {
                    loadSecSessions: ['SecFactory', function(SecFactory) {
                        return SecFactory.loadSecSessions();
                    }]
                }
            })
            .state('annexa.sec.sessions.view', {
                url: '/view/:session',
                views: {
                    "mainsec@annexa.sec": {
                        controller: 'SecViewSessionController',
                        templateUrl: './views/layout/session_view.html'
                    }
                },
                data: {
                    displayName: 'global.sec.literals.viewSessionTitle',
                    authenticate: true,
                    permissions: {
                        only: 'view_sessions'
                    }
                },
                resolve: {
                    loadSecSessionsView: ['SecFactory', '$stateParams', function (SecFactory, $stateParams) {
                        return SecFactory.loadSecSessionsView($stateParams.session);
                    }]
                }
            })
            .state('annexa.sec.proposals', {
                url: '/proposals',
                views: {
                    "mainsec@annexa.sec": {
                        controller: 'SecProposalsController',
                        templateUrl: './views/layout/proposals.html'
                    }
                },
                data: {
                    title: 'global.sec.toptitle',
                    displayName: 'global.sec.proposals.title',
                    authenticate: true,
                    permissions: {
                        only: 'view_proposals'
                    }
                }
            })
            .state('annexa.sec.proposals.edit', {
                url: '/edit/:proposal',
                views: {
                    "mainsec@annexa.sec": {
                        controller: 'SecEditProposalController',
                        templateUrl: './views/layout/edit_proposal.html'
                    }
                },
                data: {
                    title: 'global.sec.toptitle',
                    displayName: 'global.literals.newf',
                    authenticate: true,
                    permissions: {
                        only: 'view_proposals'
                    }
                },
                resolve:{
                    loadSecProposalsEdit: ['SecFactory', '$stateParams', function (SecFactory, $stateParams) {
                        return SecFactory.loadSecProposalsEdit($stateParams.proposal);
                    }],
					loadTramProposalsEdit: ['TramFactory', '$stateParams', function (TramFactory, $stateParams) {
                        return TramFactory.getUserAccountInstance($stateParams.proposal);
                    }]
                }
            })
            .state('annexa.sec.decrees', {
                url: '/decrees',
                views: {
                    "mainsec@annexa.sec": {
                        controller: 'SecDecreesController',
                        templateUrl: './views/layout/decrees.html'
                    }
                },
                data: {
                    title: 'global.sec.toptitle',
                    displayName: 'global.sec.literals.decrees',
                    authenticate: true,
                    permissions: {
                        only: 'view_decrees'
                    }
                }
            })
            .state('annexa.sec.decrees.edit', {
                url: '/edit/:decrees',
                views: {
                    "mainsec@annexa.sec": {
                        controller: 'SecEditDecreeController',
                        templateUrl: './views/layout/edit_decree.html'
                    }
                },
                data: {
                    title: 'global.sec.toptitle',
                    displayName: 'global.literals.new',
                    authenticate: true,
                    permissions: {
                        only: 'view_decrees'
                    }
                },
                resolve:{
                    loadSecDecreesEdit: ['SecFactory', '$stateParams', function (SecFactory, $stateParams) {
                        return SecFactory.loadSecDecreesEdit($stateParams.decrees);
                    }]
                }
            })
        ;
    }]);